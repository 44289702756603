<template>
  <div class="l-wrapper">
    <q-scroll-area class="l-grow q-pa-md">
      <div class="column q-col-gutter-sm">
        <q-select
          outlined
          :options="customer.data.customer"
          v-model="visit.data.visitDetail.id_customer"
          label="Company"
        ></q-select>
        <q-select
          outlined
          :options="visit.data.visitType"
          v-model="visit.data.visitDetail.type"
          label="Visit Type"
        ></q-select>
        <q-input
          outlined
          readonly
          v-model="visit.data.visitDetail.date"
          mask="date"
        >
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy
                ref="qDateProxy"
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date
                  v-model="visit.data.visitDetail.date"
                  :options="optionsFn"
                >
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
        <q-checkbox
          label="Check-In"
          true-value="1"
          false-value="0"
          v-model="visit.data.visitDetail.is_check_in"
          v-if="
            visit.data.visitDetail.date &&
              moment(visit.data.visitDetail.date).format('DDMMYY') ==
                moment().format('DDMMYY')
          "
        ></q-checkbox>
      </div>
    </q-scroll-area>
    <q-btn
      class="no-border-radius"
      color="primary"
      label="save"
      @click="visit.addVisit"
    ></q-btn>
  </div>
</template>
<script>
import { ref, reactive, inject } from "vue";
import { onMounted } from "vue";
import useVisit from "./useVisit";
import moment from "moment";
import useCustomer from "../Customer/useCustomer";
export default {
  setup() {
    const visit = useVisit();
    const customer = useCustomer();

    onMounted(async () => {
      try {
        visit.$q.loading.show({ message: "Mohon tunggu sebentar" });
        await customer.getCustomer(customer.curUser.value.id, 1);
        await visit.getType();
        visit.$q.loading.hide();
      } catch (err) {
        visit.$q.loading.hide();
      }
    });

    let optionsFn = (date) => {
      return date >= moment().format("YYYY/MM/DD");
    };

    return {
      visit,
      customer,
      moment,
      optionsFn,
    };
  },
};
</script>
